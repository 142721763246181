import * as Sentry from '@sentry/vue'

export default function useSentry() {
  function logError(exception: any) {
    Sentry.captureException(exception)
    Sentry.captureMessage(formatErrorResponse(exception))
  }

  return {
    logError,
  }
}
