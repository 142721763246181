import ApiClient from '../api-client'
import type { NumberOptionsRes } from '~/types/numbers'

class NumberService extends ApiClient {
  fetchNumberOptions(): Promise<NumberOptionsRes> {
    return this.call<NumberOptionsRes>('get', 'v1/number-options?sort=order')
  }
}

export default NumberService
