import type { FetchOptions } from 'ofetch'
import { $fetch } from 'ofetch'

// Services
import AuthService from '~/repository/services/auth'
import UserService from '~/repository/services/user'
import ComplaintService from '~/repository/services/complaint'
import NotificationService from '~/repository/services/notification'
import TransactionService from '~/repository/services/transaction'
import ActivityService from '~/repository/services/activity'
import DrawService from '~/repository/services/draw'
import DrawInviteService from '~/repository/services/draw-invite'
import PaymentService from '~/repository/services/payment'
import ChatService from '~/repository/services/chat'
import GameService from '~/repository/services/games'
import QuoteService from '~/repository/services/quote'
import LeaderBoardService from '~/repository/services/leaderboard'
import FactoesService from '~/repository/services/factoes'
import NumberService from '~/repository/services/number'
import MiscService from '~/repository/services/misc'

interface ApiInstance {
  auth: AuthService
  user: UserService
  complaint: ComplaintService
  notification: NotificationService
  transaction: TransactionService
  activity: ActivityService
  draw: DrawService
  drawInvite: DrawInviteService
  payment: PaymentService
  chat: ChatService
  game: GameService
  quote: QuoteService
  leaderboard: LeaderBoardService
  factoes: FactoesService
  number: NumberService
  misc: MiscService
}

export default function useApi() {
  const token = useCookie<null | undefined | string>('lk_token')
  const { BASE_URL } = useRuntimeConfig().public

  const fetchOptions: FetchOptions = {
    baseURL: BASE_URL,
    headers: {
      'Content-Type': 'application/json',
    },

    onRequest({ options }) {
      if (token.value) {
        options.headers = { ...options.headers } as { [key: string]: string }
        options.headers.Authorization = `Bearer ${token.value}`
      }
    },

    onResponseError({ response }) {
      if (window.location.pathname !== '/login') {
        if (response.status === 401 || response.status === 403) {
          navigateTo('/logout')
          // window.location.href = '/login'
        }
      }
    },

    onResponse() {},
  }

  /* create a new instance of $fetcher with custom option */
  const apiFetcher = $fetch.create(fetchOptions)

  /* Object containing all services we need to expose */
  const services: ApiInstance = {
    auth: new AuthService(apiFetcher),
    user: new UserService(apiFetcher),
    complaint: new ComplaintService(apiFetcher),
    notification: new NotificationService(apiFetcher),
    transaction: new TransactionService(apiFetcher),
    activity: new ActivityService(apiFetcher),
    draw: new DrawService(apiFetcher),
    drawInvite: new DrawInviteService(apiFetcher),
    payment: new PaymentService(apiFetcher),
    chat: new ChatService(apiFetcher),
    game: new GameService(apiFetcher),
    quote: new QuoteService(apiFetcher),
    leaderboard: new LeaderBoardService(apiFetcher),
    factoes: new FactoesService(apiFetcher),
    number: new NumberService(apiFetcher),
    misc: new MiscService(apiFetcher),
  }

  return {
    api: services,
  }
}
