import ApiClient from '../api-client'
import type { ComplaintRes } from '~/types/complaint'

class ComplaintService extends ApiClient {
  sendComplaint(message: string): Promise<ComplaintRes> {
    return this.call<ComplaintRes>('post', 'v1/complaints', {
      message,
    })
  }
}

export default ComplaintService
