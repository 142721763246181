import ApiClient from '../api-client'
import type {
  OngoingDrawRes,
  QuickDrawsRes,
  QuickDrawRes,
  PartakingInQuickDrawRes,
  QuickDrawSubDrawsRes,
  QuickDrawSubDrawRes,
  ServerTimeRes,
  CreateDrawParam,
  CreateDrawRes,
} from '~/types/draw'

class DrawService extends ApiClient {
  fetchOngoingDraws(): Promise<OngoingDrawRes> {
    return this.call<OngoingDrawRes>(
      'get',
      'v1/queues/ongoing-currentuser?sort=createdAt',
    )
  }

  fetchQuickDraws(): Promise<QuickDrawsRes> {
    return this.call<QuickDrawsRes>('get', 'v1/groups/users?sort=participants')
  }

  fetchQuickDraw(id: string): Promise<QuickDrawRes> {
    return this.call<QuickDrawRes>('get', `v1/groups/${id}`)
  }

  fetchFeaturedDraws(): Promise<QuickDrawSubDrawsRes> {
    return this.call<QuickDrawSubDrawsRes>(
      'get',
      `v1/subgroups/users?isFeatured=true`,
    )
  }

  createDraw(params: CreateDrawParam): Promise<CreateDrawRes> {
    return this.call<CreateDrawRes>('post', 'v1/queues', {
      subGroupId: params.subGroupId,
      groupId: params.groupId,
      inviteId: params.inviteId,
      numberOfTickets: params.numberOfTickets,
    })
  }

  fetchQuickDrawSubDraws(drawId: string): Promise<QuickDrawSubDrawsRes> {
    return this.call<QuickDrawSubDrawsRes>(
      'get',
      `v1/subgroups/users?groupId=${drawId}&sort=amount`,
    )
  }

  fetchQuickDrawSubDraw(drawId: string): Promise<QuickDrawSubDrawRes> {
    return this.call<QuickDrawSubDrawRes>('get', `v1/subgroups/${drawId}`)
  }

  checkIfAlreadyPartakingInDraw(
    drawId: string,
  ): Promise<PartakingInQuickDrawRes> {
    return this.call<PartakingInQuickDrawRes>(
      'get',
      `v1/queues/group/${drawId}/currentUser`,
    )
  }

  fetchServerTime(): Promise<ServerTimeRes> {
    return this.call<ServerTimeRes>('get', 'v1/utils/current-server-time')
  }

  exitDraw(queueId: string): Promise<boolean> {
    return this.call<boolean>('delete', `v1/queues/${queueId}`)
  }
}

export default DrawService
