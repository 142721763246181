import ApiClient from '../api-client'
import type {
  TrendingEventRes,
  EventCategoryRes,
  EventHistoryRes,
  EventsRes,
  EventRes,
  EventBuyReq,
} from '@/types/factoes'

class FactoesService extends ApiClient {
  fetchTrendingEvents(): Promise<TrendingEventRes> {
    return this.call<TrendingEventRes>('get', 'v1/event-histories/trending')
  }

  fetchCategories(): Promise<EventCategoryRes> {
    return this.call<EventCategoryRes>('get', 'v1/event-categories/users')
  }

  fetchEventHistory(): Promise<EventHistoryRes> {
    return this.call<EventHistoryRes>('get', 'v1/event-histories/purchased')
  }

  fetchEventsByCategory(categoryId: string): Promise<EventsRes> {
    return this.call<EventsRes>('get', `v1/events/?categoryId=${categoryId}`)
  }

  fetchEvent(id: string): Promise<EventRes> {
    return this.call<EventRes>('get', `v1/events/${id}`)
  }

  buyStake(payload: EventBuyReq): Promise<boolean> {
    return this.call<boolean>('post', 'v1/event-histories', {
      eventId: payload.eventId,
      unit: payload.unit,
      optionId: payload.optionId,
      buyPriceId: payload.buyPriceId,
    })
  }
}

export default FactoesService
