import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'
const breakpoints = useBreakpoints(breakpointsTailwind)
const isMedScreenUp = breakpoints.greaterOrEqual('sm')
const isDesktop = breakpoints.greater('xl')

export default defineNuxtPlugin(() => {
  return {
    provide: {
      isMedScreenUp,
      isDesktop,
    },
  }
})
