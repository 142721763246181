import type { AlertOption } from '@/types/alert'

export const useAppStore = defineStore('appStore', () => {
  const pageTitle = ref('')
  function setPageTitle(title: string) {
    pageTitle.value = title
  }

  const profileTab = ref('')
  function setProfileTab(name: string) {
    profileTab.value = name
  }

  const quickDrawTab = ref('')
  function setQuickDrawTab(name: string) {
    quickDrawTab.value = name
  }

  const inviteTab = ref('')
  function setInviteTab(name: string) {
    inviteTab.value = name
  }

  const factoesTab = ref('')
  function setFactoesTab(name: string) {
    factoesTab.value = name
  }

  const pacesetterTab = ref('')
  function setPacesetterTab(name: string) {
    pacesetterTab.value = name
  }

  const numberTab = ref('')
  function setNumberTab(name: string) {
    numberTab.value = name
  }

  const isUpdateAvailable = ref(false)
  function setAppUpdateStatus(status: boolean) {
    isUpdateAvailable.value = status
  }

  // Alert modal
  const alertOption = ref<AlertOption | null>(null)
  const isAlertModalVisible = ref(false)
  function showAlertModal(option: AlertOption) {
    alertOption.value = option
    isAlertModalVisible.value = true
  }
  function resetAlertModal() {
    alertOption.value = null
    isAlertModalVisible.value = false
  }

  return {
    pageTitle,
    profileTab,
    quickDrawTab,
    inviteTab,
    factoesTab,
    pacesetterTab,
    numberTab,
    alertOption,
    isAlertModalVisible,
    isUpdateAvailable,
    setPageTitle,
    setProfileTab,
    setQuickDrawTab,
    setInviteTab,
    setFactoesTab,
    setPacesetterTab,
    setNumberTab,
    showAlertModal,
    resetAlertModal,
    setAppUpdateStatus,
  }
})
