import ApiClient from '../api-client'
import type { ConfigurationRes } from '@/types/misc'

class MiscService extends ApiClient {
  fetchConfigurations(): Promise<ConfigurationRes> {
    return this.call<ConfigurationRes>(
      'get',
      'v1/configurations/app-configuration',
    )
  }
}

export default MiscService
