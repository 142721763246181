<template>
  <NuxtLayout>
    <main>
      <NuxtRouteAnnouncer />
      <NuxtPage />
      <AlertModal ref="alertModal" />
      <LazyAppUpdateModal v-if="showUpdateModal" ref="updateModal" />
    </main>
  </NuxtLayout>
</template>

<script setup lang="ts">
import { App } from '@capacitor/app'
import { Capacitor } from '@capacitor/core'
import AlertModal from './components/modals/AlertModal.vue'
import type AppUpdateModal from './components/modals/AppUpdateModal.vue'
import PullToRefresh from 'pulltorefreshjs'
// import mixpanel from 'mixpanel-browser'

// Reusable composables
const { api } = useApi()
const router = useRouter()
const { isAlertModalVisible } = storeToRefs(useAppStore())
const route = useRoute()
const { logError } = useSentry()
// const app = useAppStore()

onMounted(() => {
  checkForUpdate()
})

// const { MIX_PANEL_TOKEN } = useRuntimeConfig().public
/* mixpanel.init(MIX_PANEL_TOKEN, {
  debug: true,
  track_pageview: true,
  persistence: 'localStorage',
}) */

const alertModal = ref<InstanceType<typeof AlertModal> | null>(null)
watch(isAlertModalVisible, (val) => {
  if (val) {
    alertModal.value?.open()
  }
})

const updateModal = ref<InstanceType<typeof AppUpdateModal> | null>(null)
const showUpdateModal = ref(false)
watch(updateModal, (val) => {
  if (val) {
    updateModal.value?.open()
  }
})
function openUpdateModal() {
  showUpdateModal.value = true
  updateModal.value?.open()
}
async function checkForUpdate() {
  if (Capacitor.getPlatform() !== 'web') {
    const appInfo = await App.getInfo()

    try {
      const response = await api.misc.fetchConfigurations()
      if (response) {
        if (appInfo.version !== response.data.appVersion) {
          openUpdateModal()
        }
      }
    } catch (err) {
      logError(err)
    }
  }
}

/* const nuxtApp = useNuxtApp()
nuxtApp.hook('app:manifest:update', () => {
  // A new version of the app is available, reload the app
  // window.location.reload()
  app.setAppUpdateStatus(true)
}) */

const { APP_ENV } = useRuntimeConfig().public
useHead({
  bodyAttrs: {
    class: APP_ENV === 'development' ? 'debug-screens' : '',
  },
})

// Capacitor
App.addListener('backButton', ({ canGoBack }) => {
  if (canGoBack) {
    router.back()
  } else {
    App.exitApp()
  }
})

// Pull to Refresh
if (Capacitor.getPlatform() !== 'web' || route.path === '/chatroom') {
  PullToRefresh.init({
    mainElement: 'body',
    onRefresh() {
      window.location.reload()
    },
  })
  onUnmounted(() => {
    PullToRefresh.destroyAll()
  })
}
</script>
