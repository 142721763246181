import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter()
  const {
    public: { sentry, APP_ENV },
  } = useRuntimeConfig()

  if (!sentry.DSN) {
    return
  }

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentry.DSN,
    environment: APP_ENV,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],

    // Configure this whole part as you need it!
    // Performance Monitoring
    tracesSampleRate: sentry.SENTRY_TRACES_SAMPLE_RATE, // Change in prod

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],

    // Session Replay
    replaysSessionSampleRate: sentry.SENTRY_REPLAY_SAMPLE_RATE, // Change in prod
    replaysOnErrorSampleRate: sentry.SENTRY_ERROR_REPLAY_SAMPLE_RATE, // Change in prod if necessary
  })
})
