import ApiClient from '../api-client'
import type { QuoteRes } from '@/types/quote'

class QuoteService extends ApiClient {
  fetchQuotes(count: number): Promise<QuoteRes> {
    return this.call<QuoteRes>('get', `v1/quotes/${count}/random`)
  }
}

export default QuoteService
