import ApiClient from '../api-client'
import type {
  InviteHistoryRes,
  InviteOngoingRes,
  InviteAcceptRes,
  InviteDrawRes,
  CreateInviteReq,
  CreateInviteRes,
  ParticipantReq,
} from '@/types/draw-invite'

class DrawInviteService extends ApiClient {
  fetchInvite(id: string): Promise<InviteDrawRes> {
    return this.call<InviteDrawRes>('get', `v1/invites/${id}`)
  }

  fetchInviteHistory(page: number): Promise<InviteHistoryRes> {
    return this.call<InviteHistoryRes>(
      'get',
      `v1/invites?limit=30&page=${page}&sort=-updatedAt`,
    )
  }

  fetchOngoingInviteDraw(): Promise<InviteOngoingRes> {
    return this.call<InviteOngoingRes>('get', 'v1/invites/ongoing')
  }

  fetchPendingInvite(page?: number): Promise<InviteHistoryRes> {
    return this.call<InviteHistoryRes>(
      'get',
      `v1/invites?limit=100&page=${page || 1}&status=pending`,
    )
  }

  createInvite(payload: CreateInviteReq): Promise<CreateInviteRes> {
    return this.call<CreateInviteRes>('post', 'v1/invites', {
      name: payload.name,
      amount: payload.amount,
      participants: payload.noOfParticipants,
      users: payload.participants,
    })
  }

  acceptInvite(id: string): Promise<InviteAcceptRes> {
    return this.call<InviteAcceptRes>('put', `v1/invites/${id}/accept`)
  }

  rejectInvite(id: string): Promise<boolean> {
    return this.call<boolean>('put', `v1/invites/${id}/reject`)
  }

  cancelInvite(id: string): Promise<boolean> {
    return this.call<boolean>('put', `v1/invites/${id}/cancel`)
  }

  removeParticipant(payload: ParticipantReq): Promise<boolean> {
    return this.call<boolean>(
      'delete',
      `v1/invites/${payload.inviteId}/participants/${payload.userId}`,
    )
  }

  addParticipant(payload: ParticipantReq): Promise<boolean> {
    return this.call<boolean>(
      'post',
      `v1/invites/${payload.inviteId}/participants`,
      {
        userId: payload.userId,
      },
    )
  }
}

export default DrawInviteService
