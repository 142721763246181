<template>
  <div class="flex min-h-svh flex-col items-center justify-center">
    <div
      class="container mx-auto flex flex-col items-center justify-center md:w-3/4"
    >
      <ExclamationIcon class="size-24 text-red-500" />
      <h1 class="mt-8 text-center text-3xl font-bold md:text-5xl">
        An Error Occurred
      </h1>
      <p
        class="mt-5 max-w-xl text-center text-light-text dark:text-dark-light-text md:text-lg"
      >
        An error has occurred and we're working to fix the problem! We'll be up
        and running shortly.
      </p>
      <p
        class="mt-5 max-w-xl text-center text-light-text dark:text-dark-light-text md:text-lg"
      >
        {{ error?.message }}
      </p>
      <UiButton class="mt-10" rounded raised @click="handleError">
        Go Home
      </UiButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import ExclamationIcon from '@/assets/icons/exclamation.svg?component'

const error = useError()

const handleError = () => clearError({ redirect: '/' })
</script>
