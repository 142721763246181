import ApiClient from '../api-client'
import type { TransactionRes, TransactionParam } from '~/types/transaction'

class TransactionService extends ApiClient {
  fetchTransactions(params: TransactionParam): Promise<TransactionRes> {
    return this.call<TransactionRes>(
      'get',
      `v1/transactions/users/${params.userId}?limit=30&page=${params.page || 1}`,
    )
  }
}

export default TransactionService
