import ApiClient from '../api-client'
import type { ChatReq, ChatRes } from '@/types/chat'

class ChatService extends ApiClient {
  fetchChats(payload: ChatReq): Promise<ChatRes> {
    return this.call<ChatRes>(
      'get',
      `v1/room-chats?roomId=${payload.roomId}&limit=${payload.limit}&page=${payload.page}`,
    )
  }
}

export default ChatService
