import ApiClient from '../api-client'
import type { DrawRankingRes } from '@/types/leaderboard'

class LeaderBoardService extends ApiClient {
  fetchDrawRanking(page: number): Promise<DrawRankingRes> {
    return this.call<DrawRankingRes>(
      'get',
      `v1/leaderboard?limit=100&page=${page}&sort=-amount`,
    )
  }
}

export default LeaderBoardService
