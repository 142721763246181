import ApiClient from '../api-client'
import type { GameRes } from '~/types/game'

class GameService extends ApiClient {
  fetchGames(): Promise<GameRes> {
    return this.call<GameRes>('get', 'v1/raffle-options?sort=order')
  }
}

export default GameService
