import ApiClient from '../api-client'
import type {
  UserRes,
  UserForgotPinRes,
  UserResetPinReq,
  UserResetPinRes,
  UserCreatePinRes,
  UpdateUserPinReq,
  UpdateUserPinRes,
  UpdateUserPasswordReq,
  UpdateUserPasswordRes,
  UpdateUserProfileReq,
  UpdateUserProfileRes,
  UpdateUserNOKReq,
  UpdateUserNOKRes,
  DisableUserAccountReq,
  DisableUserAccountRes,
  UserSearchRes,
} from '~/types/user'

class UserService extends ApiClient {
  fetchUserInfo(): Promise<UserRes> {
    return this.call<UserRes>('post', 'v1/auth/me')
  }

  updateProfile(payload: UpdateUserProfileReq): Promise<UpdateUserProfileRes> {
    return this.call<UpdateUserProfileRes>('put', 'v1/auth/updatedetails', {
      firstName: payload.firstName,
      lastName: payload.lastName,
      email: payload.email,
      phoneNumber: payload.phoneNumber,
      nickname: payload.nickname,
      gender: payload.gender,
      dob: payload.dob,
      address: payload.address,
    })
  }

  updateNOK(payload: UpdateUserNOKReq): Promise<UpdateUserNOKRes> {
    return this.call<UpdateUserNOKRes>('put', 'v1/auth/next-of-kin', {
      firstName: payload.firstName,
      lastName: payload.lastName,
      email: payload.email,
      phoneNumber: payload.phoneNumber,
      gender: payload.gender,
      relationship: payload.relationship,
    })
  }

  forgotPin(): Promise<UserForgotPinRes> {
    return this.call<UserForgotPinRes>('post', 'v1/auth/forgot-pin')
  }

  resetPin(payload: UserResetPinReq): Promise<UserResetPinRes> {
    return this.call<UserResetPinRes>(
      'put',
      `v1/auth/reset-pin/${payload.token}`,
      {
        pin: payload.pin,
        token: payload.token,
      },
    )
  }

  createPin(pin: string): Promise<UserCreatePinRes> {
    return this.call<UserCreatePinRes>('post', 'v1/auth/create-pin', {
      pin,
    })
  }

  updatePin(payload: UpdateUserPinReq): Promise<UpdateUserPinRes> {
    return this.call<UpdateUserPinRes>('put', 'v1/auth/update-pin', {
      currentPin: payload.currentPin,
      newPin: payload.newPin,
    })
  }

  updatePassword(
    payload: UpdateUserPasswordReq,
  ): Promise<UpdateUserPasswordRes> {
    return this.call<UpdateUserPasswordRes>('put', 'v1/auth/updatepassword', {
      currentPassword: payload.currentPassword,
      newPassword: payload.newPassword,
    })
  }

  disableAccount(
    payload: DisableUserAccountReq,
  ): Promise<DisableUserAccountRes> {
    return this.call<DisableUserAccountRes>('put', 'v1/auth/updatedetails', {
      active: payload.active,
    })
  }

  searchUsers(query: string): Promise<UserSearchRes> {
    return this.call<UserSearchRes>('get', `v1/users/${query}/search?limit=5`)
  }
}

export default UserService
