<template>
  <Teleport to="body">
    <Transition name="modal">
      <div
        v-show="showModal"
        class="relative z-50"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          class="fixed inset-0 z-50 bg-black/75 backdrop-blur-sm transition ease-in-out"
          aria-hidden="true"
        ></div>

        <div
          class="fixed inset-0 z-50 w-screen"
          :class="addScrollbarClass"
          @click="closeIfDismissible()"
        >
          <div
            class="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0"
          >
            <Transition name="move-modal">
              <div
                v-if="showModal"
                class="relative w-full rounded-b-none rounded-t-2xl text-left shadow-xl sm:my-8 sm:max-w-lg sm:rounded-lg"
                @click.stop
              >
                <div
                  class="bg-white px-4 pb-4 pt-5 dark:bg-dark-primary sm:p-6 sm:pb-4"
                >
                  <slot />
                  <button v-if="showCloseButton" @click="$emit('close')">
                    <TimesIcon class="absolute right-4 top-4 mb-5 size-6" />
                  </button>
                </div>
              </div>
            </Transition>
          </div>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<script setup lang="ts">
import { Capacitor } from '@capacitor/core'
import TimesIcon from '@/assets/icons/times.svg?component'

const props = withDefaults(
  defineProps<{
    isBlocking?: boolean
    showCloseButton?: boolean
  }>(),
  {
    isBlocking: false,
    showCloseButton: true,
  },
)

defineEmits(['close'])

onMounted(() => {
  document.addEventListener('keydown', escapeHandler)
})
onUnmounted(() => {
  document.removeEventListener('keydown', escapeHandler)
  document.body.style.removeProperty('overflow')
})

const showModal = ref(false)
const scrollbarClass = ref('')

const addScrollbarClass = computed(() => {
  if (Capacitor.getPlatform() !== 'web') {
    return scrollbarClass.value
  } else {
    return 'overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-grey dark:scrollbar-thumb-grey/10'
  }
})

const escapeHandler = (e: KeyboardEvent) => {
  if (e.key === 'Escape' && showModal.value) {
    closeIfDismissible()
  }
}

watch(showModal, (value) => {
  if (value) {
    document.body.style.setProperty('overflow', 'hidden')
  } else {
    document.body.style.removeProperty('overflow')
  }
})

function closeIfDismissible() {
  if (!props.isBlocking) {
    close()
  }
}
async function open() {
  showModal.value = true
  await sleep(500)
  scrollbarClass.value =
    'overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-grey dark:scrollbar-thumb-grey/10'
}
function close() {
  showModal.value = false
  scrollbarClass.value = ''
}

defineExpose({
  open,
  close,
})
</script>
