import ApiClient from '../api-client'
import type {
  ActivityRes,
  ActivityCountRes,
  ActivityParam,
  WinnerRes,
} from '~/types/activity'

class ActivityService extends ApiClient {
  fetchActivities(params: ActivityParam): Promise<ActivityRes> {
    return this.call<ActivityRes>(
      'get',
      `v1/raffle-activities/users/${params.userId}?limit=10&page=${params.page || 1}&sort=-updatedAt`,
    )
  }

  fetchWinners(): Promise<WinnerRes> {
    return this.call<WinnerRes>('get', `v1/winners?limit=30`)
  }

  fetchTotalActivitiesCount(): Promise<ActivityCountRes> {
    return this.call<ActivityCountRes>('get', 'v1/raffle-activities/counts')
  }
}

export default ActivityService
