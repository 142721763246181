<template>
  <UiModal ref="modal" :is-blocking="true" @close="closeModal">
    <div class="pt-10">
      <div
        class="mx-auto flex size-20 items-center justify-center rounded-full shadow-custom"
        :class="alertIconBg"
      >
        <component :is="alertIcon" class="size-14" />
      </div>
      <h3
        class="mx-auto mt-5 max-w-xs text-pretty text-center text-sm font-bold"
        v-html="alertOption?.title"
      ></h3>
      <p
        v-if="alertOption?.description"
        class="mx-auto max-w-xs text-pretty text-center text-sm text-light-text dark:text-dark-light-text"
      >
        {{ alertOption?.description }}
      </p>
      <UiButton rounded raised center class="mt-6" @click="handleCallback">
        {{ alertOption?.cta || 'Okay' }}
      </UiButton>
    </div>
  </UiModal>
</template>

<script setup lang="ts">
import UiModal from '~/components/ui/UiModal.vue'
import CheckIcon from '@/assets/icons/check.svg?component'
import TimesIcon from '@/assets/icons/times.svg?component'
import ExclamationMarkIcon from '@/assets/icons/exclamation-mark.svg?component'
import ExclamationWarningIcon from '@/assets/icons/exclamation-triangle.svg?component'

// Reusable composable
const appStore = useAppStore()
const { alertOption } = storeToRefs(useAppStore())

const alertIcon = computed(() => {
  if (alertOption.value?.status === 'success') {
    return markRaw(CheckIcon)
  } else if (alertOption.value?.status === 'error') {
    return markRaw(TimesIcon)
  } else if (alertOption.value?.status === 'info') {
    return markRaw(ExclamationMarkIcon)
  } else {
    return markRaw(ExclamationWarningIcon)
  }
})
const alertIconBg = computed(() => {
  if (alertOption.value?.status === 'success') {
    return 'bg-green-500 text-white'
  } else if (alertOption.value?.status === 'error') {
    return 'bg-red-500 text-white'
  } else if (alertOption.value?.status === 'info') {
    return 'bg-blue-500 text-white'
  } else {
    return 'bg-yellow-500 text-white'
  }
})

async function handleCallback() {
  if (
    alertOption.value?.callback &&
    alertOption.value?.callback !== undefined
  ) {
    alertOption.value.callback()
    closeModal()
  } else {
    closeModal()
  }
}

// Modal
const modal = ref<InstanceType<typeof UiModal> | null>(null)

function open() {
  modal.value?.open()
}
function closeModal() {
  appStore.resetAlertModal()
  modal.value?.close()
}

defineExpose({
  open,
  closeModal,
})
</script>
