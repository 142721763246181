import ApiClient from '../api-client'
import type {
  NotificationRes,
  UpdateNotificationReq,
  UpdateNotificationRes,
} from '~/types/notification'

class NotificationService extends ApiClient {
  fetchNotifications(): Promise<NotificationRes> {
    return this.call<NotificationRes>('get', 'v1/notifications?limit=40&page=1')
  }

  updateNotificationStatus(
    payload: UpdateNotificationReq,
  ): Promise<UpdateNotificationRes> {
    return this.call<UpdateNotificationRes>('put', 'v1/auth/updatedetails', {
      isNotification: payload.isNotification,
    })
  }
}

export default NotificationService
