import ApiClient from '../api-client'
import type {
  LoginReq,
  LoginRes,
  SignUpReq,
  SignUpRes,
  ForgotPasswordRes,
  VerifyEmailRes,
  ResendVerificationEmailRes,
  ResetPasswordReq,
  ResetPasswordRes,
} from '~/types/auth'

class AuthService extends ApiClient {
  login(payload: LoginReq): Promise<LoginRes> {
    return this.call<LoginRes>('post', 'v1/auth/login', {
      email: payload.email,
      password: payload.password,
    })
  }

  register(payload: SignUpReq): Promise<SignUpRes> {
    return this.call<SignUpRes>('post', 'v1/auth/register', {
      firstName: payload.firstName,
      lastName: payload.lastName,
      email: payload.email,
      phoneNumber: payload.phoneNumber,
      nickname: payload.nickname,
      password: payload.password,
      discountCode: payload.discountCode,
    })
  }

  forgotPassword(email: string): Promise<ForgotPasswordRes> {
    return this.call<ForgotPasswordRes>('post', 'v1/auth/forgotpassword', {
      email,
    })
  }

  verifyEmail(token: string): Promise<VerifyEmailRes> {
    return this.call<VerifyEmailRes>(
      'put',
      `v1/auth/emailverification/${token}`,
    )
  }

  resendVerificationEmail(email: string): Promise<ResendVerificationEmailRes> {
    return this.call<ResendVerificationEmailRes>(
      'post',
      'v1/auth/sendemailverification',
      {
        email,
      },
    )
  }

  resetPassword(payload: ResetPasswordReq): Promise<ResetPasswordRes> {
    return this.call<ResetPasswordRes>(
      'put',
      `v1/auth/resetpassword/${payload.token}`,
      {
        password: payload.password,
      },
    )
  }

  fetchUserInfo(): Promise<ResetPasswordRes> {
    return this.call<ResetPasswordRes>('post', 'v1/auth/me')
  }
}

export default AuthService
