import ApiClient from '../api-client'
import type {
  BankRes,
  UserBankRes,
  AddUserBankReq,
  VerifyAccountNumberReq,
  VerifyAccountNumberRes,
  VerifyTransactionRes,
  VerifyAccountRes,
  WalletTransferReq,
  WalletTransferRes,
  WalletWithdrawReq,
  WalletWithdrawRes,
  // WalletKoraFundRes,
  PayWithPalmPayRes,
  PayWithOpayRes,
  PayWithProvidusRes,
} from '~/types/payment'

class PaymentService extends ApiClient {
  fetchBanks(): Promise<BankRes> {
    return this.call<BankRes>('get', 'v1/banks/palmpay')
  }

  fetchUserBanks(): Promise<UserBankRes> {
    return this.call<UserBankRes>('get', 'v1/banks/current-user')
  }

  addBank(payload: AddUserBankReq): Promise<boolean> {
    return this.call<boolean>('post', 'v1/banks', {
      bankCode: payload.bankCode,
      accountNumber: payload.accountNumber,
    })
  }

  deleteBank(id: string): Promise<boolean> {
    return this.call<boolean>('delete', `v1/banks/${id}`)
  }

  /* verifyAccountNumber(
    payload: VerifyAccountNumberReq,
  ): Promise<VerifyAccountNumberRes> {
    return this.call<VerifyAccountNumberRes>(
      'get',
      `v1/banks/verify-bank-account?accountNumber=${payload.account}&bankCode=${payload.bank}`,
    )
  } */

  verifyAccountNumber(
    payload: VerifyAccountNumberReq,
  ): Promise<VerifyAccountNumberRes> {
    return this.call<VerifyAccountNumberRes>(
      'post',
      `v1/banks/palmpay/verify-other-account`,
      {
        bank: payload.bankCode,
        account: payload.accountNumber,
      },
    )
  }

  verifyAccount(nickname: string): Promise<VerifyAccountRes> {
    return this.call<VerifyAccountRes>('get', `v1/accounts/${nickname}/verify`)
  }

  verifyTransaction(reference: string): Promise<VerifyTransactionRes> {
    return this.call<VerifyTransactionRes>(
      'get',
      `v1/transactions/verify/${reference}`,
    )
  }

  transferFund(payload: WalletTransferReq): Promise<WalletTransferRes> {
    return this.call<WalletTransferRes>('post', 'v1/accounts/transfer', {
      accountNumber: payload.accountNumber,
      amount: payload.amount,
      pin: payload.pin,
    })
  }

  withdrawFund(payload: WalletWithdrawReq): Promise<WalletWithdrawRes> {
    return this.call<WalletWithdrawRes>('post', 'v1/accounts/withdraw', {
      amount: payload.amount,
      pin: payload.pin,
    })
  }

  /* withdrawFundWithKora(payload: WalletWithdrawReq): Promise<WalletWithdrawRes> {
    return this.call<WalletWithdrawRes>('post', 'v1/accounts/withdrawfromkp', {
      amount: payload.amount,
      pin: payload.pin,
      bankId: payload.bankId,
    })
  } */

  withdrawFundWithPalmPay(
    payload: WalletWithdrawReq,
  ): Promise<WalletWithdrawRes> {
    return this.call<WalletWithdrawRes>(
      'post',
      'v1/payments/palmpay/withdraw',
      {
        amount: payload.amount,
        pin: payload.pin,
        bankId: payload.bankId,
      },
    )
  }

  /*  fundWithKora(amount: number): Promise<WalletKoraFundRes> {
    return this.call<WalletKoraFundRes>('post', 'v1/accounts/korapay-fund', {
      amount,
    })
  } */

  fundWithPalmPay(amount: number): Promise<PayWithPalmPayRes> {
    return this.call<PayWithPalmPayRes>('post', 'v1/payments/palmpay/fund', {
      amount,
    })
  }

  fundWithOPay(amount: number): Promise<PayWithOpayRes> {
    return this.call<PayWithOpayRes>('post', 'v1/payments/opay/fund', {
      amount,
    })
  }

  fundWithProvidus(): Promise<PayWithProvidusRes> {
    return this.call<PayWithProvidusRes>(
      'post',
      'v1/payments/providus/generate-account-number',
    )
  }
}

export default PaymentService
